@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;800&display=swap");

body {
  font-family: "Work Sans", sans-serif;
  margin: auto;
  @apply bg-dusk-dark text-white;
}

.button {
  @apply bg-yellow text-lg text-center cursor-pointer inline-block text-black font-bold;
  object-fit: fill;
  border-radius: 16px;
  padding: 16px 20px 14px;
  line-height: 120%;
  text-decoration: none;
  position: relative;
  box-shadow: 0 4px #be8f18;
  margin-bottom: 8px;
  display: inline-block;
}

.button:active:not([disabled]) {
  box-shadow: 0 1px #be8f18;
  top: 4px;
}

.button[disabled] {
  opacity: 0.5;
}

.button.play-button {
  @apply h-32 w-32 rounded-full md:text-2xl text-nc-2xl xl:w-44 xl:h-44 2xl:w-48 2xl:h-48;
  box-shadow: 0 6px #886610;
}

.button.play-button:active:not([disabled]) {
  box-shadow: 0 1px #be8f18;
  top: 6px;
}

.button.button-md {
  @apply text-xs px-4 py-2 rounded-lg;
}

.button.button__alternative {
  @apply bg-white text-black border-black;
  border-width: 1px;
  box-shadow: 0 4px #000;
}

.button.button__alternative:active {
  box-shadow: 0 1px #202330;
}

.button.button__alternative__highlighted {
  background-color: #303030;
  box-shadow: 0 4px #000;
  @apply text-white;
}

.button.button__alternative__highlighted:active {
  box-shadow: 0 1px #000;
}

.status-text {
  @apply self-center text-lg text-center;
}

.video-overlay {
  transition: opacity 1.5s;
}

.video-overlay.active {
  @apply opacity-100;
}

.icon-container {
  background-color: rgba(0, 0, 0, 0.7) !important;
  border-radius: 4px;
}

.sound-trigger {
  background: url(./img/icons/icon-sound-on.svg) center center no-repeat;
  background-size: 50% 50%;
}

.quit-game {
  background: url(./img/icons/icon-off.svg) center center no-repeat;
  background-size: 50% 50%;
}

.sound-trigger.sound-muted {
  background: url(./img/icons/icon-sound-off.svg) center center no-repeat;
  background-size: 50% 50%;
}

.fullscreen-trigger {
  background: url(./img/icons/icon-fullscreen.svg) center center no-repeat;
  background-size: 50% 50%;
}

.fullscreen-trigger:active {
  background-size: 45% 45%;
}

.fullscreen-trigger.is-fullscreen {
  background-image: url(./img/icons/icon-exit-fullscreen.svg);
}

.loading-spinner-wrapper {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 200px;
  margin-top: -80px;
}

.circle {
  width: 24px;
  height: 24px;
  position: absolute;
  border-radius: 50%;
  background-color: #ff74d7;
  left: 15%;
  transform-origin: 50%;
  animation: circle 0.5s alternate infinite ease;
  top: 60px;
}

@keyframes circle {
  0% {
    top: 60px;
    height: 5px;
    border-radius: 50px 50px 25px 25px;
    transform: scaleX(1.7);
  }
  40% {
    height: 20px;
    border-radius: 50%;
    transform: scaleX(1);
  }
  100% {
    top: 0%;
  }
}

.circle:nth-child(2) {
  left: 45%;
  animation-delay: 0.2s;
  background-color: #4ef1d9;
}

.circle:nth-child(3) {
  left: auto;
  right: 15%;
  animation-delay: 0.3s;
  background-color: #ffd04d;
}

.shadow {
  width: 20px;
  height: 4px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 62px;
  transform-origin: 50%;
  z-index: -1;
  left: 15%;
  filter: blur(1px);
  animation: shadow 0.5s alternate infinite ease;
  transform: scaleX(1.5);
}

@keyframes shadow {
  0% {
    transform: scaleX(1.5);
  }
  40% {
    transform: scaleX(1);
    opacity: 0.7;
  }
  100% {
    transform: scaleX(0.2);
    opacity: 0.4;
  }
}

.shadow:nth-child(4) {
  left: 45%;
  animation-delay: 0.2s;
}

.shadow:nth-child(5) {
  left: auto;
  right: 15%;
  animation-delay: 0.3s;
}

.z-60 {
  z-index: 60 !important;
}
